<template>
  <div class="container">
    <div class="nav">
      <div class="nav_title">消费记录</div>
      <div class="nav_left_btn" @click="toHome">
        <img :src="cancel" alt width="15" height="25" />
      </div>
    </div>
    <div style="height:55px"></div>
    <!--  消费详情 -->
    <div class="detail">
      <div class="first">支付金额</div>
      <div class="second" v-if="item.consumeMoney>=0">-{{ getMoney(item.consumeMoney/100) }}</div>
      <div
        class="second"
        v-if="item.consumeMoney<0"
      >+{{ getMoney(item.consumeMoney/100).split('-')[1] }}</div>
      <div class="third">
        <p class="left">支付时间</p>
        <p class="right">{{item.endTime}}</p>
      </div>
      <div class="fourth">
        <p class="left">订单号</p>
        <p class="right">{{ item.id ? item.id : '更正' }}</p>
      </div>
      <div class="fifth">
        <div>消费明细</div>
        <div class="money">
          <p class="left">优惠钱包</p>
          <div
            v-if="item.use_coupon && item.use_coupon>=0"
            class="right"
          >-{{ getMoney(item.use_coupon/100) }}</div>
          <div
            v-else-if="item.use_coupon && item.use_coupon<0"
            class="right"
          >+{{ getMoney(item.use_coupon/100).split('-')[1] }}</div>
          <div v-else class="right">-0.00</div>
        </div>
        <div class="money">
          <p class="left">现金钱包</p>
          <div
            v-if="item.use_balance && item.use_balance>=0"
            class="right"
          >-{{ getMoney(item.use_balance/100) }}</div>
          <div
            v-else-if="item.use_balance && item.use_balance<0"
            class="right"
          >+{{ getMoney(item.use_balance/100).split('-')[1] }}</div>
          <div v-else class="right">-0.00</div>
        </div>
        <div class="money">
          <p class="left">补助钱包</p>
          <div
            v-if="item.use_allowance && item.use_allowance>=0"
            class="right"
          >-{{ getMoney(item.use_allowance/100) }}</div>
          <div
            v-else-if="item.use_allowance && item.use_allowance<0"
            class="right"
          >+{{ getMoney(item.use_allowance/100).split('-')[1] }}</div>
          <div v-else class="right">-0.00</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cancel from "@/assets/cancel.png";
import { timeToDate, getMoney } from "../../utils/util";

export default {
  name: "Detail",
  data() {
    return {
      cancel,
      item: {},
    };
  },
  created() {
    this.item = this.$route.query.item;
  },
  methods: {
    timeToDate,
    getMoney,
    toHome() {
      this.$router.back();
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  & > .nav {
    display: block;
    text-align: center;
    height: 48px;
    color: white;
    overflow: hidden;
    position: fixed;
    width: 100%;
    border-bottom: 1px solid rgba(181, 201, 210, 0.6);
    background-color: #ffffff;

    & > .nav_title {
      display: block;
      font-size: 1.1rem;
      overflow: hidden;
      white-space: nowrap;
      line-height: 49px;
      text-overflow: ellipsis;
      color: #607483;
      width: 60%;
      margin: 0 auto;
      background-color: #ffffff;
    }

    & > .nav_left_btn {
      float: left;
      position: absolute;
      border-width: 9px 5px 9px 7px;
      border-color: transparent;
      background-size: 12px 1.25rem;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 4px 0 15px;
      top: 9px;
      left: 0px;
      min-height: 30px;
      display: flex;
      align-items: center;

      & > .back {
        font-size: 1.1rem;
        margin-left: 3px;
      }
    }
    & > .nav_left_btn:active {
      opacity: 0.5;
    }
    & > .nav_right_btn {
      float: right;
      position: absolute;
      border-width: 9px 4px 9px 3px;
      border-color: transparent;
      background: transparent;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 8px 0 2px;
      top: 8px;
      right: 0;
      min-width: 52px;
      min-height: 30px;
      display: flex;
      align-items: center;
    }

    & > .nav_right_btn_click {
      opacity: 0.5;
    }
  }
  .detail {
    width: 95%;
    height: calc(100vh - 55px);
    background: #ffffff;
    margin: 0 auto;
    .first {
      padding-top: 1.5rem;
      text-align: center;
      color: rgba(52, 52, 52);
      font-size: 1.1rem;
    }
    .second {
      height: 3rem;
      line-height: 3rem;
      text-align: center;
      color: rgba(51, 51, 51);
      font-size: 1.3rem;
      font-weight: 900;
    }
    .third,
    .fourth {
      display: flex;
      padding: 0 1rem;
      color: rgba(90, 90, 90);
      .left {
        width: 30%;
      }
      p {
        margin-top: 0;
      }
    }
    .fifth {
      width: 90%;
      margin: 0 auto;
      border: 1px solid rgba(225, 225, 225);
      border-radius: 0.25rem;
      background: rgba(251, 251, 251);
      div:nth-child(1) {
        width: 100%;
        text-align: center;
        height: 3rem;
        line-height: 3rem;
        color: rgba(85, 85, 85);
        font-weight: 900;
      }
      .money {
        display: flex;
        color: rgba(171, 171, 171);
        padding: 0 0.5rem;
        .left {
          width: 45%;
        }
        .right {
          width: 55%;
          text-align: right;
        }
        p {
          margin-top: 0;
        }
      }
    }
  }
}
</style>
